import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { NotificationService } from './modules/student/notification/notification.service';
import { getToken } from './auth/auth.role';
import { environment } from './common/constants/constants';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class SignalrService {
    connection: any;

    constructor(private notificationService: NotificationService) {
        this.connection = null;
    }

    // Establish a connection to the SignalR server hub
    public initiateSignalrConnection(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(environment.BASE_SOCKET_URL_V1 + 'studentNotification')
                .build();

            this.setSignalrClientMethods();

            this.connection
                .start()
                .then(() => {
                    this.joinRoom();
                    resolve();
                })
                .catch((error: any) => {
                    console.log(`SignalR connection error: ${error}`);
                    reject();
                });
        });
    }

    public joinRoom() {
        const token = getToken();

        if (token) {
            this.connection.invoke('JoinGroup', token).catch((error: any) => {
                console.log('ERR_JOIN', error);
            });
        }
    }

    // This method will implement the methods defined in the ISignalrDemoHub interface in the SignalrDemo.Server .NET solution
    private setSignalrClientMethods(): void {
        this.connection.on('StudentNotification', (message: string) => {
            const data: any = JSON.parse(message);
            const { _id, content, title, userCreatedInfo } = data;

            const newNoty = {
                content,
                isRead: false,
                notifyId: _id,
                sentDate: moment(),
                sentDateTS: moment().unix(),
                title,
                userSent: userCreatedInfo?.name,
            };

            this.notificationService.addListNoty(newNoty);

            this.notificationService.incUnRead();
        });
    }
}
